import React from "react";
import "./App.css";
import Row from "./Row";
import requests from "./requests";
import Banner from "./Banner";
import Nav from "./Nav";

function App() {
  return (
    <div className="app">
      <Nav />
      <Banner />
      <Row
        title="KOENFLIX ORIGINALS"
        fetchUrl={requests.fetchNetflixOriginals}
        isLargeRow
      />
      <Row title="KOENFLIX COMEDYS" fetchUrl={requests.fetchComedyMovies} />
      <Row title="KOENFLIX TOP RATED" fetchUrl={requests.fetchTopRated} />
      <Row
        title="KOENFLIX ACTION MOVIES"
        fetchUrl={requests.fetchActionMovies}
      />
      <Row
        title="KOENFLIX COMEDY MOVIES"
        fetchUrl={requests.fetchComedyMovies}
      />
      <Row
        title="KOENFLIX HORROR MOVIES"
        fetchUrl={requests.fetchHorrorMovies}
      />
      <Row
        title="KOENFLIX ROMANCE MOVIES"
        fetchUrl={requests.fetchRomanceMovies}
      />
      <Row
        title="KOENFLIX DOCUMENTARIES"
        fetchUrl={requests.fetchDocumantaries}
      />
    </div>
  );
}

export default App;
